import React from 'react'
import { Link } from 'react-router-dom'
import '../utils/Imput/input.css'
import { useState, useEffect } from 'react'
import { Validation } from '../utils/validation'
import { isMobileOnly, MobileOnlyView } from 'react-device-detect';
import EscuelaForm from './EscuelaForm'
import errorTexts from '../utils/Texts/errorTexts.json'
import { useLangContext } from '../../Context/LangContext'
import { useSchool } from './hooks/use-school.hook'



const EscuelaInscripcion = () => {
  const {selectedLanguage} = useLangContext()
  const errorMessages = errorTexts[selectedLanguage];
  let [infoInput, setinfoInput]=useState({
    'courses.introduccionAlSeguroDeCaucion': 'false',
    'courses.cobranzaYRefacturacion': 'false',
    'courses.suscripcionDeRiesgos': 'false',
    'courses.garantiasJudicialesYSiniestros': 'false',
    userName: "",
    userLastName: "",
    company: "",
    email: "",
    phone:"",
    dni:"",
    howToKnow:""
  })
  const [sent, setSent]= useState(false)
  const [errors, setErrors]= useState({})
  const enroledSchoolResponse = useSchool(infoInput)

  const handleChange = (e, name, type, required = false, maxLength = false, minLength = false) => {
    const specialField = e.target.name === 'courses.introduccionAlSeguroDeCaucion' || e.target.name === 'courses.cobranzaYRefacturacion' || e.target.name === 'courses.suscripcionDeRiesgos' || e.target.name === 'courses.garantiasJudicialesYSiniestros'
    const specialFieldValue = specialField && e.target.value === 'true' ? 'false' : 'true'
    specialField && setErrors(errors['courses'] = false)
    const {changedInfoInput, value, err}= Validation(e, name, type, required,maxLength, minLength, infoInput, errors, errorMessages)
      setinfoInput({...changedInfoInput, [e.target.name]: specialField ? specialFieldValue : value});
      setErrors(err);
}

  const handleSubmit=(e)=>{
    e.preventDefault();
    for (const error in errors) {
      if (errors[error]) {
        return;
      }
    }
    if(infoInput['courses.introduccionAlSeguroDeCaucion'] === 'false' && infoInput['courses.cobranzaYRefacturacion']  === 'false' && infoInput['courses.suscripcionDeRiesgos']  === 'false' && infoInput['courses.garantiasJudicialesYSiniestros'] === 'false') {
      setErrors({...errors, courses: 'Debe seleccionar un curso'})
      return;
    }

    setinfoInput({...infoInput, isValid: true})
  }

  useEffect(() => {
  if(enroledSchoolResponse.success) {
    setSent(true)
    window.scrollTo(0,0);
  }
}, [enroledSchoolResponse])

  return (
    <>
   
    <section className={ isMobileOnly ? "display-none" :'inscription-backgound'} >
    <div  className='info-hero'>
        <div className='place-logo'>
        <img src="/img/LogoEscuelaAFZ.png" alt="Escuela de Caución AFZ"></img>
        </div>
        <div className="inscription-block">
        <div className='sub-school-menu'>
            <div className='items'>
                <h5 className="item-school-selected">INSCRIPCIÓN</h5>   
              <Link to="/escuela/info" >
                <h5 className="item-school">VOLVER</h5>   
              </Link> 
            </div>
            <div>
              <Link to="/" className='school-home-link'>
                <h5>HOME</h5>
              </Link>
            </div> 
          </div>
          <EscuelaForm errors={errors} infoInput={infoInput} sent={sent} handleSubmit={handleSubmit} handleChange={handleChange} enroledSchoolResponse={enroledSchoolResponse} />         
           </div>
          </div>
          <div class="push"></div>
      </section>
      
      <MobileOnlyView>
      <section className='info-backgound' > 
        <div className='inscription-school-mobile-box'>
          <div className='logo-school-box-mobile'><img src="/img/LogoEscuelaAFZ.png" alt="Escuela de Caución AFZ"></img></div>
          <EscuelaForm errors={errors} infoInput={infoInput} sent={sent} handleSubmit={handleSubmit} handleChange={handleChange} enroledSchoolResponse={enroledSchoolResponse} />  
          </div>
          <div class="push"></div>
         </section>
      </MobileOnlyView>
      </>
  )
}

export default EscuelaInscripcion